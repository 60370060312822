import React from "react"
import { Link, graphql } from "gatsby"
import { dateLarge, cleanHTML } from "../../utils/Utils"
import Page from "../page/Page"
// import SocialIcon from "../social/SocialIcon"
import NavArticle from "../../components/main/NavArticle"
import _ from "lodash"

export default props => {
  let edge = props.data.allContentfulOpinion.edges[0]
  return (
    <Page
      class="opinion"
      slug={`opinion/${props.pageContext.slug}`}
      title={edge.node.titulo}
    >
      <article className="article">
        <header className="article-header">
          <h1 className="title">{edge.node.titulo}</h1>
          <div className="date">{dateLarge(edge.node.fecha)}</div>
          {/* <div className="share-buttons">
            <SocialIcon
              name="facebook"
              url={`https://twitter.com/share?url="https://laoruga.netlify.com/"`}
              isLink={false}
              fx={() => this.shareFacebook()}
            />
            <SocialIcon
              name="twitter"
              url={`https://twitter.com/share?url="https://laoruga.netlify.com/"`}
              isLink={false}
              fx={() => this.shareTweet()}
            />
          </div> */}
        </header>

        <main
          className="article-main"
          dangerouslySetInnerHTML={{
            __html: cleanHTML(edge.node.texto.childMarkdownRemark.html),
          }}
        />
        {edge.node.categorias && (
          <div className="tags">
            {edge.node.categorias.map((item, i) => (
              <Link
                key={i}
                className="tag"
                to={`/categorias/${_.kebabCase(item)}`}
              >
                {item}
              </Link>
            ))}
          </div>
        )}
        <footer className="article-footer">
          <NavArticle
            route="/opinion/"
            prev={props.pageContext.prev}
            next={props.pageContext.next}
          />
        </footer>
      </article>
    </Page>
  )
}

export const opinionItemQuery = graphql`
  query opinionItemQuery($slug: String!) {
    allContentfulOpinion(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          titulo
          fecha
          texto {
            childMarkdownRemark {
              html
            }
          }
          categorias
        }
      }
    }
  }
`
