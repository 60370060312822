import React from "react"
import { Link } from "gatsby"
import "./Navs.css"

export default props => {
  const route = props.route
  const prev = props.prev
  const next = props.next
  return (
    <nav className="items-nav">
      {prev && (
        <Link className="items-nav--left" to={route + prev}>
          <i className="material-icons">navigate_before</i>
          <span>anterior</span>
        </Link>
      )}
      {next && (
        <Link className="items-nav--right" to={route + next}>
          <span>siguiente</span>
          <i className="material-icons">navigate_next</i>
        </Link>
      )}
    </nav>
  )
}
